import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Hero from '../components/hero'
import Layout from '../components/layout'
  
  
import styles from '../components/article.module.scss'
import richTextStyles from '../components/rich-text.module.scss'

import ToggleButton from '../components/toggle-button'


// import ArticleListing from '../components/article-listing'
// 
// import ProductListing from '../components/product-listing'
// 
// import Img from 'gatsby-image'
// 
// import HomepageTileCollapsible from '../components/homepage-tile-collapsible'
// 
 import ContactCollapsible from '../components/contact-collapsible'
// 
 import MetadataGenerator from '../components/metadata-generator'
// 
// import PopularSolutions from '../components/popular-solutions'
// 
// import Tile from '../components/tile'
// 
// import ContentTile from '../components/content-tile'
// 
// import UserGuide from '../components/user-guide'
// 
// import BoxContacts from '../components/box-contacts'
// 
// import tileStyles from '../components/tile.module.scss'

import LeadSection from '../components/lead-section'
import RichTextRenderer from '../components/rich-text-renderer'


//cannot have relative links for metadata 
import { Location } from '@reach/router'



class RootIndex extends React.Component {

  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteUrl = get(this, 'props.data.site.siteMetadata.siteUrl')
   
    const supportChangesContacts = get(this, 'props.data.supportChangesContacts');

    
    const pageContentCheck = get(this, 'props.data.contentfulSupportSitePage')
    
    var pageTitle = '';
    var eyebrow = '';
    var openingHTML = '';
    
    var largeArticle = '';
    var articleClass = '';
    var articleItemProp = '';
    
    
    var supportUpdates = '';
    
    var articleContent = '';
    var articleContentLead = '';
    
    
    
console.log(pageContentCheck)

var supportArticleClass = [styles.articleWide, richTextStyles.article].join(' ')

if(pageContentCheck === null) {
  console.log('no page! ')
  
  pageTitle = "Important Information regarding our Community Forum & Social Media channels"

  supportUpdates = <><div itemprop="articleBody" class={supportArticleClass}><p>As of July 2023 our Community Forum and social media support channels will be closed.</p><p>Support articles for Humax Retail and Sony TVs with YouView can still be found at <a href="https://support.youview.com">support.youview.com</a></p><p>Please see the below support information for your box and/or internet service provider</p></div><div class="panel-group support contact" id="contact" role="tablist" aria-multiselectable="true"><ContactCollapsible key={supportChangesContacts.nodes[0].id} contactCategory={supportChangesContacts.nodes[0]} /></div></>
}
else {
  pageTitle = pageContentCheck.title
  if(pageContentCheck && pageContentCheck.body) {
    articleContent = pageContentCheck.body.json
   articleClass = styles.articleWide 
  }
  supportUpdates = <div class="panel-group support contact" id="contact" role="tablist" aria-multiselectable="true"><ContactCollapsible key={supportChangesContacts.nodes[0].id} contactCategory={supportChangesContacts.nodes[0]} /></div>
}
   // console.log(topSolutions)


    const metaDescription = "Welcome to YouView support. Find out how to set up and use YouView and browse popular topics to help you with all of your YouView products."


    

var excludedSlug = 'mobile-app';
console.log(process.env.MAIN_SITE_URL)


    return (

      <Layout alertslocation={this.props.location} title={siteTitle}>
        <MetadataGenerator 
            title={siteTitle} 
            siteTitle={siteTitle} 
            description={metaDescription} 
            urlBase={siteUrl}
            url={this.props.location.href} />

          
        <section className={'white'}>
        <article role={"main"} itemScope={"true"}  itemType={"https://schema.org/TechArticle"}>

        <div className={"container"}>
          <LeadSection
          title={pageTitle} 
          eyebrow={eyebrow} 
          subheading={openingHTML}
          description={articleContentLead}
          largeArticle={largeArticle}
           />
           <RichTextRenderer containerClass={articleClass} itemProp={articleItemProp} articleJSON={articleContent} />
        </div>
        
        {supportUpdates}
        
        </article>
        </section>
      </Layout>

    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    
  site {
      siteMetadata {
        title
        siteUrl
      }
    }
  supportChangesContacts: allContentfulSupportSiteContactSection(filter: {title: {eq: "Box & TV service providers"}}) {
    nodes {
      title
      id
      description {
        childMarkdownRemark {
          html
        }
      }
      contacts {
        ... on ContentfulSupportSiteContact {
          id
          title
          logo {
            file {
              details {
                image {
                  width
                  height
                }
              }
              url
            }
          }
          number {
            childMarkdownRemark {
              html
            }
          }
          lineDetails {
            childMarkdownRemark {
              html
            }
          }
          website {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
  contentfulSupportSitePage(slug: {glob: "*homae*"}) {
    title
    id
    contentful_id
    body {
      json
    }
  }
  }
`
