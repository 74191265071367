import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types';

import ContactRow from '../components/contact-row'


//import Tile from '../components/tile'

import styles from './contact.module.scss'







class ContactCollapsible extends React.Component {
  render() {
    const contentType = this.props.contactCategory;
    const contactList = this.props.contactCategory.contacts;

    var description = '';

    var webite = '';

    if(this.props.contactCategory.website) {
      website = contact.website.childMarkdownRemark.html;
    }

    if (!this.props.contactCategory.description) {
      description = '';
    }
    else {
      description = <div className={"panel-body"} dangerouslySetInnerHTML={{
                        __html: contentType.description.childMarkdownRemark.html,
                      }}
                      />

    }
    //console.log(contactList)

    return (
<div class="panel panel-default">
<div class="panel-heading" role="tab" id={'heading' + contentType.id}>
        <h4 class="panel-title">
          <a class="accordion-toggle" role="button" aria-expanded="false" class="collapsed" data-toggle="collapse" data-parent="#contact" href={'#' + contentType.id} aria-controls={contentType.id}>
            {contentType.title}
          </a>
        </h4>
      </div>
     <div id={contentType.id} className={[styles.panel, 'panel-collapse collapse'].join(' ')} role="tabpanel" aria-labelledby={'heading' + contentType.id}>
      {description}
      <table className={[styles.contactTable, 'table'].join(' ')}>
              <tbody>
                    {/* {contactList.map((contact, i) => (
                        <tr key={contact.id}>
                        <td><img alt={contact.title} width={contact.logo ? contact.logo.file.details.image.width : null} height={contact.logo ? contact.logo.file.details.image.height : null} src={contact.logo ? contact.logo.file.url : null} /></td>
                        <td dangerouslySetInnerHTML={{
                         __html: contact.number.childMarkdownRemark.html,
                       }} />
                       <td dangerouslySetInnerHTML={{
                        __html: contact.lineDetails.childMarkdownRemark.html,
                      }} />
                       <td dangerouslySetInnerHTML={{
                        __html: contact.website ? contact.website.childMarkdownRemark.html : null,
                      }} />
                      </tr>
                      
    ))} */}
    {contactList.map((contact, i) => (
      <ContactRow contact={contact} key={contact.id}/>
    ))}
    </tbody>
    </table>
      
      </div>
      </div>
    )
  }
}

export default ContactCollapsible