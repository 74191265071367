import React from 'react'

import { Helmet } from 'react-helmet'

import metaDataImage from '../components/metadata/support-site-og-image.png'


class HelmetMetadataGenerator extends React.Component {
     componentDidMount() {



    }

  render() {
    const pageTitle = this.props.title;
    const siteTitle = this.props.siteTitle;
    const description = this.props.description;
    const url = this.props.url;
    const urlBase = this.props.urlBase;

    const metaDataImageUrl = urlBase + metaDataImage;

    var title = pageTitle;

    var contentType = 'website'

    var metaDescription = ''

    if(this.props.description) {
      metaDescription = description
    }


    if(pageTitle != siteTitle) {
      title = [pageTitle, siteTitle].join(' - ')
      contentType = 'article'
    }

    var hideStyle = {display: 'none'};

    // if(this.props.urlBase) {
    //   metaDataImageUrl = urlBase + metaDataImage;
    // }
    return (
      <Helmet title={title} defer={false}>
      <link rel="apple-touch-icon" sizes="180x180" href="/favicons/apple-touch-icon.png?v=bOMQ56xJRQ" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicons/favicon-32x32.png?v=bOMQ56xJRQ" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicons/favicon-16x16.png?v=bOMQ56xJRQ" />
      <link rel="manifest" href="/favicons/site.webmanifest?v=bOMQ56xJRQ" />
      <link rel="mask-icon" href="/favicons/safari-pinned-tab.svg?v=bOMQ56xJRQ" color="#009cee" />
      <link rel="shortcut icon" href="/favicons/favicon.ico?v=bOMQ56xJRQ" />
      <meta name="msapplication-TileColor" content="#009cee" />
      <meta name="msapplication-config" content="/favicons/browserconfig.xml?v=bOMQ56xJRQ" />
      <meta name="theme-color" content="#000000" />
      {/* General */}
      <meta name="description" content={metaDescription} />
      <meta name="image" content={metaDataImageUrl} />
      <link rel="canonical" href={url} />
      {/* OpenGraph */}
      <meta property="og:url" content={url} />
      <meta property="og:type" content={contentType} />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={metaDataImageUrl} />
      <meta property="og:image:width" content="900" />
      <meta property="og:image:height" content="473" />
      <meta property="og:site_name" content={siteTitle}/>

      {/* Twitter Card  */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@YouViewBusiness" />
      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={metaDataImageUrl} />
      <meta name="twitter:image:alt" content={metaDescription} />
      </Helmet>
    )
  }
}

export default HelmetMetadataGenerator
